<template>
    <ui-create
        name="products"
        v-model="form"
        :title="title"
        :id="id"
    >
        <template #body >
            <ui-crud
                v-model="form"
                :rows="rows"
            />

            <!-- <v-stepper v-model="e1">
                <v-stepper-header>
                    <v-stepper-step
                        :complete="e1 > 1"
                        step="1"
                    >
                        <h3>Dados do Produto</h3>
                    </v-stepper-step>

                    <v-stepper-step
                        :complete="e1 > 2"
                        step="2"
                    >
                        Regra Fiscal
                    </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-form ref="form" >
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-text-field
                                        label="Descrição"
                                        v-model="form.descricao"
                                        counter
                                        maxlength="128"
                                        :rules="[
                                            form_descricao_rules.required, form_descricao_rules.counter,
                                                form_descricao_rules.min
                                        ]"
                                    ></v-text-field>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-text-field
                                        label="Unidade Comercial"
                                        v-model="form.unidade"
                                        maxlength="2"
                                        v-mask="'AA'"
                                        :rules="[
                                            form_unidade_rules.required, form_unidade_rules.counter,
                                                form_unidade_rules.min
                                        ]"
                                    ></v-text-field>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-autocomplete
                                        label="Grupo"
                                        v-model="form.id_grupo"
                                        :items="sectionsGroup"
                                        item-text="descricao"
                                        item-value="id"
                                    ></v-autocomplete>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-autocomplete
                                        label="Sub-Grupo"
                                        v-model="form.id_sub_grupo"
                                        :items="sectionsSubGroup"
                                        item-text="descricao"
                                        item-value="id"
                                    ></v-autocomplete>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-autocomplete
                                        label="Fornecedor"
                                        v-model="form.id_people"
                                        :items="sectionsPeople"
                                        item-text="nome"
                                        item-value="id"
                                    ></v-autocomplete>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <vuetify-money
                                        label="Comissão a Vista"
                                        v-model="form.comissao_a_vista"
                                        v-bind:options="options_form_comissao_a_vista"
                                        :rules="[form_comissao_a_vista_rules.counter]"
                                    ></vuetify-money>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <vuetify-money
                                        label="Comissão a Prazo"
                                        v-model="form.comissao_a_prazo"
                                        v-bind:options="options_form_comissao_a_prazo"
                                        :rules="[form_comissao_a_prazo_rules.counter]"
                                    ></vuetify-money>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <vuetify-money
                                        label="Margem"
                                        v-model="form.margem"
                                        v-bind:options="options_form_margem"
                                        :rules="[form_margem_rules.counter]"
                                    ></vuetify-money>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <vuetify-money
                                        label="Peso Bruto"
                                        v-model="form.peso_bruto"
                                        v-bind:options="options_form_peso_bruto"
                                        :rules="[form_peso_bruto_rules.counter]"
                                    ></vuetify-money>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-text-field
                                        label="Observação"
                                        v-model="form.observacao"
                                        counter
                                        maxlength="256"
                                        :rules="[form_observacao_rules.counter]"
                                    ></v-text-field>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-autocomplete
                                        label="Setor de Impressão"
                                        v-model="form.id_setor_impressao"
                                        :items="sectionsPrintSector"
                                        item-text="descricao"
                                        item-value="id"
                                    ></v-autocomplete>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <vuetify-money
                                        label="Preço de Venda"
                                        v-model="form.preco_venda"
                                        v-bind:options="options_form_preco_venda"
                                        :rules="[form_preco_venda_rules.required, 
                                            form_preco_venda_rules.counter
                                        ]"
                                    ></vuetify-money>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <vuetify-money
                                        label="Promoção"
                                        v-model="form.promocao"
                                        v-bind:options="options_form_promocao"
                                        :rules="[form_promocao_rules.counter]"
                                    ></vuetify-money>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <vuetify-money
                                        label="Preço de Compra"
                                        v-model="form.preco_compra"
                                        v-bind:options="options_form_preco_compra"
                                        :rules="[form_preco_compra_rules.counter]"
                                    ></vuetify-money>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <vuetify-money
                                        label="Estoque"
                                        v-model="form.estoque"
                                        v-bind:options="options_form_estoque"
                                        :rules="[form_estoque_rules.counter]"
                                    ></vuetify-money>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <vuetify-money
                                        label="Custo"
                                        v-model="form.custo"
                                        v-bind:options="options_form_custo"
                                        :rules="[form_custo_rules.counter]"
                                    ></vuetify-money>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <vuetify-money
                                        label="Custo Medio"
                                        v-model="form.custo_medio"
                                        v-bind:options="options_form_custo_medio"
                                        :rules="[form_custo_medio_rules.counter]"
                                    ></vuetify-money>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <vuetify-money
                                        label="Estoque Mínimo"
                                        v-model="form.estoque_minimo"
                                        v-bind:options="options_form_estoque_minimo"
                                        :rules="[form_estoque_minimo_rules.counter]"
                                    ></vuetify-money>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <vuetify-money
                                        label="Estoque Máximo"
                                        v-model="form.estoque_maximo"
                                        v-bind:options="options_form_estoque_maximo"
                                        :rules="[form_estoque_maximo_rules.counter]"
                                    ></vuetify-money>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-text-field
                                        label="Produto Delivery"
                                        v-model="form.id_produto_delivery"
                                        maxlength="5"
                                        v-mask="'#####'"
                                        :rules="[form_id_produto_delivery_rules.nan]"
                                    ></v-text-field>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-dialog
                                        ref="dialog"
                                        v-model="modal"
                                        :return-value.sync="form.inicio_promocao"
                                        persistent
                                        width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                label="Inicio da Promoção"
                                                v-model="form.inicio_promocao"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="form.inicio_promocao"
                                            scrollable
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="modal = false"
                                            >
                                                Cancela
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.dialog.save(form.inicio_promocao)"
                                            >
                                                Ok
                                            </v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>

                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-dialog
                                        ref="dialogFim"
                                        v-model="modalFim"
                                        :return-value.sync="form.fim_promocao"
                                        persistent
                                        width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                label="Fim da Promoção"
                                                v-model="form.fim_promocao"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="form.fim_promocao"
                                            scrollable
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="modalFim = false"
                                            >
                                                Cancela
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.dialogFim.save(form.fim_promocao)"
                                            >
                                                Ok
                                            </v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-checkbox
                                        label="Ativo?"
                                        v-model="form.ativo"
                                        true-value="s"
                                        false-value="n"
                                    ></v-checkbox>
                                </v-col>

                                <v-col>
                                    <v-checkbox
                                        label="Ativo para Cardapio?"
                                        v-model="form.cardapio"
                                        true-value="s"
                                        false-value="n"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-form>

                        <v-btn
                            color="primary"
                            @click="e1 = 2"
                        >
                            Continue
                        </v-btn>

                        <v-btn text @click.prevent="clearForm()">
                            Limpar
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-form ref="form">
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="4"
                                >
                                    <v-autocomplete
                                        label="Regra Fiscal"
                                        v-model="form.id_regra_fiscal"
                                        :items="sectionsFiscalRules"
                                        item-text="descricao"
                                        item-value="id"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-form>

                        <v-btn
                            color="primary"
                            @click="e1 = 1"
                        >
                            Voltar
                        </v-btn>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper> -->

        </template>
    </ui-create>
</template>
<script>
import uiCreate from '@/templates/create'
import {uiCrud} from '@/components/ui'
export default {
    name: 'vwproductsCreate',
    components: {
        uiCreate,
        uiCrud
    },
    data(){
        return {
            load: false,
            e1: 1,
            modal: false,
            modalFim: false,
            form: {
                descricao: '',
                unidade: '',
                id_grupo: null,
                id_sub_grupo: null,
                id_pessoa: null,
                comissao_a_vista: '',
                comissao_a_prazo: '',
                margem: '',
                peso_bruto: '',
                observacao: '',
                id_setor_impressao: null,
                id_pessoa_fornecedor: null,
                cardapio: null,
                preco_venda: '',
                promocao: '',
                preco_compra: '',
                estoque: '',
                custo: '',
                custo_medio: '',
                estoque_minimo: '',
                estoque_maximo: '',
                id_produto_delivery: '',
                inicio_promocao: '',
                fim_promocao: '',
                ativo: null,
                id_regra_fiscal: null
            },

            rows : [
                // Informações principais.
                [
                    {
                        name: 'descricao',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Nome do Produto',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 8,
                            lg: 8,
                            xl: 8
                        }
                    },
                    {
                        name: 'unidade',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Tipo de Unidade',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 6,
                            sm: 6,
                            md: 2,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        name: 'peso_bruto',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Peso',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 6,
                            sm: 6,
                            md: 2,
                            lg: 2,
                            xl: 2
                        }
                    },
                ], [ // Informações de grupo, subgrupo e impressão
                    {
                        name: 'id_setor_impressao',
                        value: '',
                        type: 'string',
                        field: 'autocomplete',
                        label: 'Setor',
                        handle: 'default',
                        service: 'setores_impressao',
                        serviceParams: null,
                        optionText: 'descricao',
                        options: [],
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        },
                    },
                    {
                        name: 'id_grupo',
                        value: '',
                        type: 'string',
                        field: 'autocomplete',
                        label: 'Grupo',
                        handle: 'default',
                        service: 'grupos',
                        serviceParams: null,
                        optionText: 'descricao',
                        options: [],
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        },
                    },
                    {
                        name: 'id_sub_grupo',
                        value: '',
                        type: 'string',
                        field: 'autocomplete',
                        label: 'Sub Grupo',
                        handle: 'default',
                        service: 'sub_grupos',
                        serviceParams: null,
                        optionText: 'descricao',
                        options: [],
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        },
                    },
                    {
                        name: 'id_pessoa_fornecedor',
                        value: '',
                        type: 'string',
                        field: 'autocomplete',
                        label: 'Fornecedor',
                        handle: 'default',
                        service: 'pessoas',
                        serviceParams: "id,nome/tipo_pessoa='FOR'",
                        optionText: 'nome',
                        options: [],
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 3,
                            xl: 3
                        },
                    },
                ], [
                    {
                        name: 'preco_compra',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Valor Compra',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 6,
                            sm: 3,
                            md: 3,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        name: 'preco_venda',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Valor Venda',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 6,
                            sm: 3,
                            md: 3,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        name: 'comissao_a_vista',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Comissão A Vista',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 6,
                            sm: 3,
                            md: 3,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        name: 'comissao_a_prazo',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Comissão A Prazo',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 6,
                            sm: 3,
                            md: 3,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        name: 'custo',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Custo',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 6,
                            sm: 3,
                            md: 3,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        name: 'margem',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Margem De Lucro',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 6,
                            sm: 3,
                            md: 3,
                            lg: 2,
                            xl: 2
                        }
                    },
                ], [
                    {
                        name: 'promocao',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Valor Promoção',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 2,
                            xl: 2
                        }
                    },
                    {
                        name: 'inicio_promocao',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Ínicio da Promoção',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 5,
                            xl: 5
                        }
                    },
                    {
                        name: 'fim_promocao',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Fim da Promoção',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 5,
                            xl: 5
                        }
                    },
                ], [
                    {
                        name: 'observacao',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Complemento Descrição',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12
                        }
                    },
                ], [
                    {
                        name: 'ativo',
                        value: '',
                        type: 'string',
                        field: 'checkbox',
                        label: 'Produto está ativo?',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12
                        }
                    },
                ]
            ],

            sectionsGroup: [],

            sectionsSubGroup: [],

            sectionsPeople: [],

            sectionsPrintSector: [],

            sectionsFiscalRules: [],

            form_descricao_rules: {
                required: value => !!value || 'Descrição é obrigatória.',
                counter: value => value.length <= 128 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },

            form_unidade_rules: {
                required: value => !!value || 'Unidade é obrigatória.',
                counter: value => value.length <= 2 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },

            options_form_comissao_a_vista: {
                prefix: "%",
                length: 3,
                precision: 1
            },
            form_comissao_a_vista_rules: {
                counter: value => value.length <= 4 || 'Dados inválido!'
            },

            options_form_comissao_a_prazo: {
                prefix: "%",
                length: 3,
                precision: 1
            },
            form_comissao_a_prazo_rules: {
                counter: value => value.length <= 4 || 'Dados inválido!'
            },

            options_form_margem: {
                prefix: "%",
                length: 5,
                precision: 2
            },
            form_margem_rules: {
                counter: value => value.length <= 6 || 'Dados inválido!'
            },

            options_form_peso_bruto: {
                prefix: "KG",
                length: 5,
                precision: 3
            },
            form_peso_bruto_rules: {
                counter: value => value.length <= 6 || 'Dados inválido!'
            },

            form_observacao_rules: {
                counter: value => value.length <= 256 || 'Dados inválido!'
            },

            options_form_preco_venda: {
                prefix: "R$",
                length: 7,
                precision: 2
            },
            form_preco_venda_rules: {
                required: value => !!value || 'Preço de venda é obrigatório.',
                counter: value => value.length <= 9 || 'Dados inválido!'
            },

            options_form_promocao: {
                prefix: "R$",
                length: 7,
                precision: 2
            },
            form_promocao_rules: {
                counter: value => value.length <= 9 || 'Dados inválido!'
            },

            options_form_preco_compra: {
                prefix: "R$",
                length: 7,
                precision: 2
            },
            form_preco_compra_rules: {
                counter: value => value.length <= 9 || 'Dados inválido!'
            },

            options_form_estoque: {
                length: 7,
                precision: 3
            },
            form_estoque_rules: {
                counter: value => value.length <= 9 || 'Dados inválido!'
            },

            options_form_custo: {
                length: 7,
                precision: 3
            },
            form_custo_rules: {
                counter: value => value.length <= 9 || 'Dados inválido!'
            },

            options_form_custo_medio: {
                length: 7,
                precision: 3
            },
            form_custo_medio_rules: {
                counter: value => value.length <= 9 || 'Dados inválido!'
            },

            options_form_estoque_minimo: {
                length: 7,
                precision: 3
            },
            form_estoque_minimo_rules: {
                counter: value => value.length <= 9 || 'Dados inválido!'
            },

            options_form_estoque_maximo: {
                length: 7,
                precision: 3
            },
            form_estoque_maximo_rules: {
                counter: value => value.length <= 9 || 'Dados inválido!'
            },

            form_id_produto_delivery_rules: {
                nan: value => !isNaN(value) || 'Dados incorreto!'
            }
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id
        },

        title(){
            return this.id ? `Editar Produto: ${this.id}` : 'Cadastrar Produto'
        }
    },

    methods: {
        clearForm: function() {
            for (let prop in this.form) {
                this.form[prop] = ''
            }
        },

        save: function(){
            if(this.$refs.form.validate()){
                this.load = true
                if(this.id !== undefined){
                    this.update()
                }else{
                    this.create()
                }
            }
        },

        update: function(){
            this.load = true
            fetch(process.env.VUE_APP_API + '/products/update/' + this.id, {
                method: 'PUT',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.returnRead()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao atualizar o produto!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.load = false
                })
        },

        create: function(){
            this.load = true
            fetch(process.env.VUE_APP_API + '/products/create', {
                method: 'POST',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.alertSuccess = {
                        text: 'Produto cadastrado com sucesso.',
                        show: true,
                        type: 'red darken-1'
                    }
                    this.clearForm()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao gravar o produto!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.load = false
                })
        },

        getData: function(){
            this.load = true
            fetch(process.env.VUE_APP_API + '/products/read/' + this.id, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.form = resJSON.response[0].listhelper[0]
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.load = false
                })
        },

        getSectionsGroup: function(){
            fetch(process.env.VUE_APP_API + '/generics/description/grupos', {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.sectionsGroup = resJSON.response
                })
                .catch(error => {
                    console.error(error)
                })
        },

        getSectionsSubGroup: function(){
            fetch(process.env.VUE_APP_API + '/generics/description/sub_grupos', {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.sectionsSubGroup = resJSON.response
                })
                .catch(error => {
                    console.error(error)
                })
        },

        getSectionsPeople: function(){
            fetch(process.env.VUE_APP_API + '/generics/description/pessoas', {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.sectionsPeople = resJSON.response
                })
                .catch(error => {
                    console.error(error)
                })
        },

        getSectionsPrint: function(){
            fetch(process.env.VUE_APP_API + '/generics/description/setores_impressao', {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.sectionsPrintSector = resJSON.response
                })
                .catch(error => {
                    console.error(error)
                })
        },

        getSectionsFiscalRules: function(){
            fetch(process.env.VUE_APP_API + '/generics/description/regrasfiscais', {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.sectionsFiscalRules = resJSON.response
                })
                .catch(error => {
                    console.error(error)
                })
        }
    },

    mounted(){
        this.$root.$on('load-data-products', (data) => {
            this.form = data
        })
    }
}
</script>